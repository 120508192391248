<template>
    <CollapseCard
        :title = "title"
        icon  = "link"
    >
        <b-alert
            show
            variant="info"
        >
            Copy and paste the link below or right click the QR code and select 'Save Image As' to save the QR code link.
        </b-alert>
        <b-input-group>
            <b-input
                :value="link"
                disabled
            />
            <b-input-group-append>
                <b-button
                    v-if="copied"
                    variant="success"
                    @click="copyURL(link)"
                >
                    <font-awesome-icon icon="check" class="mr-2" />
                    Copied
                </b-button>
                <b-button
                    v-else
                    variant="primary"
                    @click="copyURL(link)"
                >
                    <font-awesome-icon icon="clone" class="mr-2" />
                    Copy
                </b-button>
            </b-input-group-append>
        </b-input-group>
        <div ref="qr-parent" class="text-center">
            <qr-code
                class="d-flex justify-content-center mt-3"
                :text="link"
                :size="200"
            />
            <b-button
                class="mt-2"
                variant="primary"
                @click="downloadQrCode()"
            >
                <font-awesome-icon icon="download" class="mr-2" />
                Download QR Code
            </b-button>
        </div>
    </CollapseCard>
</template>
<script>
import VueQRCodeComponent from 'vue-qrcode-component'
import CollapseCard from '@/components/CollapseCard.vue';

export default {
    props: ["title", "link", "filename"],
    components: { 'qr-code': VueQRCodeComponent, CollapseCard },
    data() {
        return { copied: false };
    },
    methods: {
        async copyURL(mytext) {
            try {
                await navigator.clipboard.writeText(mytext);
                this.copied = true;
                alert('Link copied');
                setTimeout(() => {
                    this.copied = false;
                }, 5000);
            } catch($e) {
                alert('Could not copy link.');
            }
        },
        downloadQrCode() {
            const parent = this.$refs['qr-parent'];
            const img = parent.querySelector("img");
            const link = document.createElement('a');
            link.href = img.src;
            link.download = this.filename || "qrcode.png";
            link.click();
        }
    }
}
</script>