<template>
    <b-card
        class="mt-3"
        no-body
    >
        <button
            class="sc-btn block pill my-3 mx-4"
            @click="show = !show"
        >
            <font-awesome-icon
                :icon="(show) ? 'chevron-down' : 'chevron-right'"
                class="float-left mt-1"
            ></font-awesome-icon>
            <font-awesome-icon
                v-if="icon"
                :icon="icon"
                class="mr-1"
            ></font-awesome-icon>
            {{ title }}
        </button>
        <b-collapse
            v-model="show"
        >
            <b-card-body>
                <slot></slot>
            </b-card-body>
        </b-collapse>
    </b-card>
</template>
<script>
export default {
    name: "sc-collapse",
    props: ["icon", "title"],
    data() {
        return {
            show: false
        };
    }
}
</script>